import Swiper, { Navigation } from 'swiper';
import MmenuLight from  'mmenu-light/src/mmenu-light.js';
import MicroModal from 'micromodal';
import ScrollOut from "scroll-out";
//https://micromodal.vercel.app/

Swiper.use([Navigation]);

document.addEventListener("DOMContentLoaded", () => {


    MicroModal.init();

    ScrollOut({
        //once: 'true',
    });

    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    });

    //Nav items 
    const listItems = document.querySelectorAll('.sub-nav ul li');

    for (var i = 0; i < listItems.length; i++) {
        listItems[i].addEventListener('mouseover', function(e) {
            for (let sibling of this.parentNode.children) {
                if (sibling !== this) sibling.classList.add('opacity-50');
            }
        });

        listItems[i].addEventListener('mouseleave', function(e) {
            for (let sibling of this.parentNode.children) {
                if (sibling !== this) sibling.classList.remove('opacity-50');
            }
        });
    }

    const internalBanner = document.querySelector('.section-hero-internal.img-blur');
    if(internalBanner){
        internalBanner.classList.add('loaded');
    }


    // Swiper

    const swiper = new Swiper('.student-swiper', {
       // loop: true,
        spaceBetween: 10,
        allowTouchMove: false,
        slidesPerView: 'auto',
        speed: 500,

         breakpoints: {
            1024: {
              spaceBetween: 20,
              centeredSlides: true,
            },

            1280: {
              spaceBetween: 25,
               centeredSlides: true,
            },

        },
    
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    const swiperGallery = new Swiper('.gallery-swiper', {
       // loop: true,
        spaceBetween: 10,
        allowTouchMove: false,
        slidesPerView: 1,

        breakpoints: {
            480: {
                slidesPerView: 2,
            },

            768: {
                slidesPerView: 3,
            },

            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
             
            },

            1280: {
                slidesPerView: 3,
                spaceBetween: 25,
            },

        },
    
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    // Tabs
    const sectionTabs = document.querySelector('.section-course-tabs');

    if (sectionTabs) {

        function TabsNavigation() {

            var bindAll = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].addEventListener('click', change, false);
                }
            }

            var clear = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].classList.remove('active');
                    var id = menuElements[i].getAttribute('data-tab');
                    document.getElementById(id).classList.remove('active');
                }
            }

            var change = function(e) {
                clear();
                e.target.classList.add('active');
                var id = e.currentTarget.getAttribute('data-tab');
                document.getElementById(id).classList.add('active');
            }

          bindAll();
        }

        TabsNavigation();
    }
 

    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    //Click function
    function bindEvent(el, eventName, eventHandler) {
	    if (el.addEventListener){
	        el.addEventListener(eventName, eventHandler, false);
	    } else if (el.attachEvent){
	        el.attachEvent('on'+eventName, eventHandler);
	    }
	}

    const clearSearchForm = document.querySelector('.clear-button');
    const searchForm = document.querySelector('.form-search-filters');
    if(clearSearchForm){
        clearSearchForm.addEventListener('click',  function(e) {
            console.log("clearing");
            searchForm.reset();
            detectQueryString();
            console.log("clearing completed");     
        });
    }
    function detectQueryString() {
        var currentQueryString = window.location.search;
        if (currentQueryString) {
            window.history.pushState({}, document.title, "/" + "students-and-learning/courses/");
            location.reload();
            return true;
        } else {
          return false;
        }
    }

    //Adding courses to download list
    const saveCourseBtns = document.querySelectorAll('.save-course-btn');
    if(saveCourseBtns.length){
        for (var i = 0; i < saveCourseBtns.length; i++) {
            saveCourseBtns[i].addEventListener('click',  function(e) {
                var course = this.getAttribute('data-course-id');
                var lincoln =  this.getAttribute('data-lincoln-id');
                var optionalTitle = this.parentNode.querySelector('[data-course-save-title]');
                if(this.classList.contains('save')){
                    var remove = 0;
                }else{
                    var remove = 1;
                }
                axios({
                    method:'post',
                    url:'/students-and-learning/courses/saveCourse/',
                    baseURL: '/',
                    data: {
                        course: course,
                        remove: remove,
                        lincolnID: lincoln
                      }
                   })
                   .then(response => {
                        const addedCourse = response.data;
                        if(addedCourse.success == true){
                            if(this.classList.contains('save')){
                                this.classList.remove('save');
                                this.classList.add('saved');
                                this.title = "Course saved";
                                if (optionalTitle) optionalTitle.innerText = 'Saved'
                            }else{
                                this.classList.remove('saved');
                                this.classList.add('save');
                                this.title = "Save this course and print all saved courses at once.";
                                if (optionalTitle) optionalTitle.innerText = 'Save'
                            }
                        }else{
                            console.log(addedCourse.message);
                        }
                   })
                   .catch(error => {
                       console.log(error);
                   });
            });
        }
    }

    let radioVal;
    const radios = document.querySelectorAll('input[name="EnquiryType"]');
    if(radios.length){
        radios.forEach(radio => {
            radio.addEventListener('click', function () {
              radioVal = radio.value;
              if(radioVal == 'general'){
                document.getElementById('general-tab').classList.add('active');
                document.getElementById('general-tab').classList.remove('hidden');
                document.getElementById('absence-tab').classList.add('hidden');
                document.getElementById('absence-tab').classList.remove('active');
              }else{
                document.getElementById('general-tab').classList.add('hidden');
                document.getElementById('general-tab').classList.remove('active');
                document.getElementById('absence-tab').classList.add('active');
                document.getElementById('absence-tab').classList.remove('hidden');
              }
              console.log(radioVal);
            });
        });
    }


    // stop video when close modal

    var stopVideos = function () {
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });
    };

    const modalClose = document.querySelectorAll('[data-micromodal-close]');

    if(modalClose.length){ 
        for (var i = 0; i < modalClose.length; i++) {
            modalClose[i].addEventListener('click',  function() {
                stopVideos();
            });
        }
    }

    const requiredGeneralFields = document.querySelectorAll('.required-general');
    const requiredAbsenceFields = document.querySelectorAll('.required-absence');
    const formHasError = document.querySelector('.form-has-error');
    if(formHasError){
        var formType = formHasError.getAttribute('data-contact-type');
        if(formType == 'general'){
            document.getElementById('general-radio').checked = true;
            document.getElementById('general-tab').classList.add('active');
            document.getElementById('general-tab').classList.remove('hidden');
            document.getElementById('absence-tab').classList.add('hidden');
            document.getElementById('absence-tab').classList.remove('active');
            if (requiredGeneralFields.length) {
                requiredGeneralFields.forEach(function(formField) {
                    formField.classList.add('has-error')
                });
            }
        }else{
            document.getElementById('absence-radio').checked = true;
            document.getElementById('general-tab').classList.add('hidden');
            document.getElementById('general-tab').classList.remove('active');
            document.getElementById('absence-tab').classList.add('active');
            document.getElementById('absence-tab').classList.remove('hidden');
            if (requiredAbsenceFields.length && formHasError) {
                requiredAbsenceFields.forEach(function(formField) {
                    formField.classList.add('has-error')
                });
            }
        }
    }
});